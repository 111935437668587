<template>
  <v-container>
    <loader v-if="loading"></loader>
    <table-component
      v-if="!loading"
      :items="items"
      :edit="false"
      :delet="true"
      :select="USER.userInfo.departments"
      :sel-id="depId"
      @delete-button="deleteButton"
      @set-select="
        depId = $event;
        setBody(depId);
      "
    ></table-component>
  </v-container>
</template>

<script>
import Loader from "../components/Loader.vue";
import TableComponent from "@/components/TableComponent";
import hotlineNumber from "../model/hotlineNumber.js";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { TableComponent, Loader },
  data() {
    return {
      loading: false,
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 2, name: "Номер" },
        ],
        body: [],
      },
      depId: 5,
    };
  },
  computed: {
    ...mapGetters({
      DEPARTMENT: "Department/STATE",
      USER: "User/STATE",
      CITY: "City/STATE",
      PHONE: "Phone/STATE",
    }),
  },
  async created() {
    this.loading = true;
    await this.checkPowers();
    await this.getDepartment(this.$root.city);
    if (this.USER.userInfo.departments.length > 0) {
      this.depId = this.USER.userInfo.departments[0].id;
      await this.setBody(this.depId);
    } else {
      this.loading = false;
      this.$notify({
        group: "app",
        type: "error",
        title: "Ошибка",
        text:
          "нет доступных филиалов у данного аккаунта в " +
          this.CITY.allCity.filter((e) => e.id == this.$root.city)[0].name,
      });
    }
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getHotlineList: "Phone/GET_HOTLINE",
      deleteHotline: "Phone/DELETE_HOTLINE",
      getDepartment: "Department/GET_DEPARTMENT",
    }),
    checkPowers() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
        this.loading = false;
      }
    },
    async setBody(id) {
      this.loading = true;
      await this.getHotlineList(id);
      let model = new hotlineNumber();
      this.items.body = model.setBody(this.PHONE.hotlineList);
      this.loading = false;
    },
    async deleteButton(item) {
      this.loading = true;
      let errors = [];
      for (let i = 0; i < item.length; i++) {
        let response = await this.deleteHotline(item[i].id);
        if (response.type !== "success") {
          errors.push(item[i].id);
        }
      }
      if (errors.length !== 0) {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: errors,
        });
      }
      await this.setBody(this.depId);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.phone-control__header {
  width: 100%;
  height: 80px;
  background-color: white;
  border-radius: 10px;
}
</style>
